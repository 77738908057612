<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{cvCardTitle}}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary" @click="gpath_aptitude_recoAdd">{{cvbtnAddNew}}</b-button>
          </template>
          <template v-slot:body>
            <b-row class="row w-100">
              <!-- Frontend Search -->
              <!-- <b-col sm="4" md="4" lg="4" xl="4" class="mb-3">
                <b-form-input
                  id="search"
                  v-model="filter"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>
              </b-col>Frontend Search -->

              <!-- Backend Search -->
              <b-col class="col-12 col-sm-8 col-md-5 col-lg-4 mb-2">
                <b-form-input class="form-control"
                  @input="searchgpath_aptitude_recoWithParams"
                  v-model="search_param"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>
              </b-col><!-- Backend Search -->
            </b-row>
            <b-row>
              <b-col class="container_minheight" md="12" v-if="gpath_aptitude_recoObjList && gpath_aptitude_recoObjList.length > 0">
                <b-table
                  hover
                  responsive
                  :items="gpath_aptitude_recoObjList"
                  :fields="columns"
                  :no-border-collapse="true"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :current-page="currentBatchNo"
                  :per-page="dataPerPage"
                >
                  <template v-slot:cell(created_on)="data">
                    {{data.item.created_on | dateFormat}}
                  </template>
                  <template v-slot:cell(action)="data">
                    <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="gpath_aptitude_recoEdit('MODAL', data.item)" v-if="!data.item.editable"><i class="ri-ball-pen-fill m-0"></i></b-button>
                    <b-button variant=" iq-bg-danger" @click="showGpath_aptitude_recoDeleteDialog(data.item)" size="sm"><i class="ri-delete-bin-line m-0"></i></b-button>
                  </template>
                </b-table>
              </b-col>
              <b-col class="container_minheight" md="12" v-else>
                No data found
              </b-col>
            </b-row>
            <b-col sm="12" md="12" class="my-1">
                <b-row>
                  <b-col sm="12" md="7">
                    <b-btn variant="primary" @click="loadMoreData" v-if="showLoadMoreBtn">Load More</b-btn>
                  </b-col>
                  <b-col sm="5" md="5">
                    <b-pagination
                      v-model="currentBatchNo"
                      :total-rows="totalRows"
                      :per-page="dataPerPage"
                      align="fill"
                      size="sm"
                      class="my-0"
                    ></b-pagination>
                  </b-col>
                </b-row>
              </b-col>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal
      v-model="showModelGpath_aptitude_recoAdd"
      scrollable
      :title="cvAddModalHeader"
    >
      <gpathAptitudeRecoAdd :propOpenedInModal="true" @emitCloseGpath_aptitude_recoAddModal="closeGpath_aptitude_recoAddModal" />
      <template #modal-footer="">
        <b-button size="sm" @click="closeGpath_aptitude_recoAddModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal
      v-model="showModelGpath_aptitude_recoEdit"
      scrollable
      :title="cvEditModalHeader"
    >
      <gpathAptitudeRecoEdit :propOpenedInModal="true" :propGpath_aptitude_recoObj="gpath_aptitude_recoEditObj" @emitCloseGpath_aptitude_recoEditModal="closeGpath_aptitude_recoEditModal" />
      <template #modal-footer="">
        <b-button size="sm" @click="closeGpath_aptitude_recoEditModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal v-model="showModelGpath_aptitude_recoDelete" size="sm" title="Delete" ok-title="Delete" cancel-title="Cancel">
      <p>{{cvDelMessage}}</p>
      <template #modal-footer="">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="showModelGpath_aptitude_recoDelete = false">
          {{cvbtnModalCancel}}
        </b-button>
        <b-button size="sm" variant="primary" @click="gpath_aptitude_recoDelete()">
          {{cvbtnModalDelOk}}
        </b-button>
      </template>
    </b-modal>

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>
<script>
import { gpathAptitudeRecos } from "../../../FackApi/api/GpathAptitudeReco.js"
import gpathAptitudeRecoAdd from "./GpathAptitudeRecoAdd"
import gpathAptitudeRecoEdit from "./GpathAptitudeRecoEdit"
import moment from "moment"
import ApiResponse from "../../../Utils/apiResponse"
import { socialvue } from "../../../config/pluginInit.js"

export default {
  name: "gpathAptitudeRecoList",
  components: {
    gpathAptitudeRecoAdd,
    gpathAptitudeRecoEdit
  },
  data () {
    return {
      cvCardTitle: "gpath Aptitude Recos",
      cvbtnAddNew: "Add New",
      cvSearchText: "Type to Search",
      cvbtnModalDelOk: "Delete",
      cvDelMessage: "Are you sure?",
      cvEditModalHeader: "Edit GPath Aptitude Reco",
      cvAddModalHeader: "Add GPath Aptitude Reco",
      cvbtnModalCancel: "Cancel",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "GPath Aptitude Reco",
      showModelGpath_aptitude_recoAdd: false,
      showModelGpath_aptitude_recoEdit: false,
      showModelGpath_aptitude_recoDelete: false,
      sortBy: "name",
      sortDesc: false,
      columns: [
        { label: "# ", key: "id", class: "text-left align-text-top", sortable: true },

        { label: "Name", key: "aptitude_name", class: "text-left align-text-top", sortable: true },
        { label: "Score", key: "aptitude_score", class: "text-left align-text-top", sortable: true },
        { label: "Reco Category", key: "reco_category", class: "text-left align-text-top", sortable: true },
        { label: "Class", key: "class", class: "text-left align-text-top", sortable: true },
        { label: "Desc", key: "reco_desc", class: "text-left align-text-top", sortable: true },
        { label: "Url", key: "reco_url", class: "text-left align-text-top", sortable: true },
        { label: "Action ", key: "action", class: "text-left align-text-top", sortable: false }
      ],
      gpath_aptitude_recoObjList: [],
      gpath_aptitude_recoEditObj: null,
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      delObj: null,
      whereFilter: {},
      search_param: "",
      currentBatchNo: 1,
      dataPerPage: 25,
      showLoadMoreBtn: true
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  async mounted () {
    socialvue.index()
    this.gpath_aptitude_recoList()
  },
  methods: {
    /**
     * searchgpath_aptitude_recoWithParams
     */
    async searchgpath_aptitude_recoWithParams () {
      if (this.whereFilter.search_param.length == 0) {
        this.showLoadMoreBtn = true
      }
      this.currentBatchNo = 1
      this.gpath_aptitude_recoList()
    },
    /**
     * loadMoreData
     */
    async loadMoreData () {
      this.currentBatchNo = this.currentBatchNo + 1
      this.gpath_aptitude_recoList(true)
    },
    /**
     * gpath_aptitude_recoList
     */
    async gpath_aptitude_recoList (loadViaLoadMore = false) {
      try {
        // setting data for pagination
        this.whereFilter.currentBatchNo = this.currentBatchNo
        this.whereFilter.dataPerPage = this.dataPerPage
        this.whereFilter.search_param = this.search_param

        let gpathAptitudeRecoListResp = await gpathAptitudeRecos.gpath_aptitude_recoList(this, this.whereFilter)
        if (gpathAptitudeRecoListResp.resp_status) {
          if (this.whereFilter.search_param.length >= 0 && !loadViaLoadMore) {
            this.gpath_aptitude_recoObjList = [ ...gpathAptitudeRecoListResp.resp_data.data ]
          }
          else {
            this.gpath_aptitude_recoObjList = [ ...this.gpath_aptitude_recoObjList, ...gpathAptitudeRecoListResp.resp_data.data ]
          }
          this.showLoadMoreBtn = true

          // Adding the serial numbers:
          this.gpath_aptitude_recoObjList.forEach((s, index) => { s.id = index + 1 })
          this.totalRows = this.gpath_aptitude_recoObjList.length
        }
        else {
          this.showLoadMoreBtn = false
          this.currentBatchNo = this.currentBatchNo - 1
          this.toastMsg = gpathAptitudeRecoListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.error("Exception occurred at gpath_aptitude_recoList() and Exception:", err)
      }
    },
    /**
     * gpath_aptitude_recoAdd
     */
    gpath_aptitude_recoAdd (type) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/admin/gpath_aptitude_reco_add")
        }
        else {
          this.showModelGpath_aptitude_recoAdd = true
        }
      }
      catch (err) {
        console.error("Exception occurred at gpath_aptitude_recoAdd() and Exception:", err.message)
      }
    },
    /**
     * gpath_aptitude_recoEdit
     */
    gpath_aptitude_recoEdit (type, item) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/admin/gpath_aptitude_reco_edit/" + this.gpath_aptitude_recoEditObj.gpar_id)
        }
        else {
          this.gpath_aptitude_recoEditObj = item
          this.showModelGpath_aptitude_recoEdit = true
        }
      }
      catch (err) {
        console.error("Exception occurred at gpath_aptitude_recoEdit() and Exception:", err.message)
      }
    },
    /**
     * showGpath_aptitude_recoDeleteDialog
     */
    showGpath_aptitude_recoDeleteDialog (item) {
      try {
        this.delObj = item
        this.showModelGpath_aptitude_recoDelete = true
      }
      catch (err) {
        console.error("Exception occurred at showGpath_aptitude_recoDeleteDialog() and Exception:", err.message)
      }
    },
    /**
     * gpath_aptitude_recoDelete
     */
    async gpath_aptitude_recoDelete () {
      try {
        let gpathAptitudeRecoDelResp = await gpathAptitudeRecos.gpath_aptitude_recoDelete(this, this.delObj.gpar_id)
        await ApiResponse.responseMessageDisplay(this, gpathAptitudeRecoDelResp)

        if (gpathAptitudeRecoDelResp && !gpathAptitudeRecoDelResp) {
          this.showModelGpath_aptitude_recoDelete = false
          return false
        }

        let index = this.gpath_aptitude_recoObjList.indexOf(this.delObj)
        this.gpath_aptitude_recoObjList.splice(index, 1)
        this.totalRows = this.totalRows - 1
        this.showModelGpath_aptitude_recoDelete = false
      }
      catch (err) {
        console.error("Exception occurred at gpath_aptitude_recoDelete() and Exception:", err.message)
      }
    },
    /**
     * closeGpath_aptitude_recoAddModal
     */
    closeGpath_aptitude_recoAddModal (gpathAptitudeRecoAddData) {
      try {
        if (gpathAptitudeRecoAddData) {
          if (this.gpath_aptitude_recoObjList && this.gpath_aptitude_recoObjList.length >= 1) {
            let gpathAptitudeRecoObjLength = this.gpath_aptitude_recoObjList.length
            let lastId = this.gpath_aptitude_recoObjList[gpathAptitudeRecoObjLength - 1]["id"]
            gpathAptitudeRecoAddData.id = lastId + 1
          }
          else {
            this.gpath_aptitude_recoObjList = []
            gpathAptitudeRecoAddData.id = 11111
          }

          gpathAptitudeRecoAddData.created_on = moment()
          this.gpath_aptitude_recoObjList.unshift(gpathAptitudeRecoAddData)
          this.totalRows = this.totalRows + 1
        }

        this.showModelGpath_aptitude_recoAdd = false
      }
      catch (err) {
        console.error("Exception occurred at closegpath_aptitude_recoAddModal() and Exception:", err.message)
      }
    },
    /**
     * closeGpath_aptitude_recoEditModal
     */
    closeGpath_aptitude_recoEditModal () {
      try {
        this.showModelGpath_aptitude_recoEdit = false
      }
      catch (err) {
        console.error("Exception occurred at closeGpath_aptitude_recoEditModal() and Exception:", err.message)
      }
    }
  }
}
</script>
