/*eslint-disable */
<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">{{ cvCardTitle }}</h4>
          </template>
          <template v-slot:body>
            <p v-if="!propOpenedInModal">{{ cvCardSubHeader }}</p>
            <form action="#">
              <div class="form-row">
                <div class="col-md-12 mb-3">
                  <label for="validationaptitude_name">
                    {{cvAptitudeNameLabel}}</label
                  >
                  <input
                    v-model="vmGpath_aptitude_recoFormData.aptitude_name"
                    type="text"
                    class="form-control"
                    required
                  />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationaptitude_score">
                    {{cvAptitudeScoreLabel}}</label
                  >
                  <input
                    v-model="vmGpath_aptitude_recoFormData.aptitude_score"
                    type="text"
                    class="form-control"
                    required
                  />
                  <small class="input_ex"> Ex- score 100 50 </small>
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationreco_category">
                    {{cvRecoCategoryLabel}}</label
                  >
                  <input
                    v-model="vmGpath_aptitude_recoFormData.reco_category"
                    type="text"
                    class="form-control"
                    required
                  />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationclass">
                    {{cvClassLabel}}</label
                  >
                  <input
                    v-model="vmGpath_aptitude_recoFormData.class"
                    type="text"
                    class="form-control"
                    required
                  />
                  <small class="input_ex"> Ex- Comma separated 11,12 </small>
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationreco_desc">
                    {{cvRecoDescLabel}}</label
                  >
                  <textarea
                    v-model="vmGpath_aptitude_recoFormData.reco_desc"
                    type="text"
                    class="form-control textarea"
                    required>
                  </textarea>
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationreco_url">
                    {{cvRecoUrlLabel}}</label
                  >
                  <input
                    v-model="vmGpath_aptitude_recoFormData.reco_url"
                    type="text"
                    class="form-control"
                    required
                  />
                </div>
              </div>
              <div class="form-group">
                <button v-if="cvLoadingStatus" type="button" class="btn btn-primary" :disabled="cvLoadingStatus">
                   <b-spinner label="Spinning"></b-spinner>
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="gpath_aptitude_recoAdd()"
                >
                  {{ cvSubmitBtn }}
                </button>
              </div>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast
      v-model="showToast"
      name="Toast"
      :auto-hide-delay="toastVariant === 'danger' ? 10000 : 1000"
      :variant="toastVariant"
      :title="toastTitle"
    >
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>

<script>
import { gpathAptitudeRecos } from "../../../FackApi/api/GpathAptitudeReco"
import ApiResponse from "../../../Utils/apiResponse"
import { socialvue } from "../../../config/pluginInit"

export default {
  name: "gpathAptitudeRecoAdd",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      cvCardTitle: "Add GPath Aptitude Reco",
      cvCardSubHeader: "Add GPath Aptitude Reco",
      cvSubmitBtn: "Add",
      cvAptitudeNameLabel: "aptitude name",
      cvAptitudeScoreLabel: "aptitude score",
      cvRecoCategoryLabel: "reco category",
      cvClassLabel: "class",
      cvRecoDescLabel: "reco desc",
      cvRecoUrlLabel: "reco url",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      cvLoadingStatus: false,
      toastTitle: "GPath Aptitude Reco Added",
      vmGpath_aptitude_recoFormData: Object.assign({}, this.initFormData()),
      vmGpath_aptitude_recoDesc: null,
      vmGpath_aptitude_recoType: null
    }
  },
  mounted () {
    socialvue.index()
  },
  methods: {
    /**
     * initFormData
     */
    initFormData () {
      return {
        "aptitude_name": "",
        "aptitude_score": "",
        "reco_category": "",
        "class": "",
        "reco_desc": "",
        "reco_url": ""
      }
    },
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmGpath_aptitude_recoFormData) {
          if (!this.vmGpath_aptitude_recoFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
      }
    },
    /**
     * gpath_aptitude_recoAdd
     */
    async gpath_aptitude_recoAdd () {
      // if (!this.validate()) {
      //   return false
      // }
      try {
        this.cvLoadingStatus = true
        let gpathAptitudeRecoAddResp = await gpathAptitudeRecos.gpath_aptitude_recoAdd(this, this.vmGpath_aptitude_recoFormData)
        await ApiResponse.responseMessageDisplay(this, gpathAptitudeRecoAddResp)
        if (gpathAptitudeRecoAddResp && !gpathAptitudeRecoAddResp.resp_status) {
          return false
        }
        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseGpath_aptitude_recoAddModal", gpathAptitudeRecoAddResp.resp_data)
        }
        this.vmGpath_aptitude_recoFormData = Object.assign({}, this.initFormData())
      }
      catch (err) {
        console.error("Exception occurred at gpath_aptitude_recoAdd() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    }
  }
}
</script>
<style scoped>
 textarea{
    height: 195px !important;
    overflow-y: scroll;
    line-height: 18px !important;
  }
</style>
